:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/bg-menu-blue.jpg);
	--navbarbreak: 768px;
	--primary: #0e71b8;
	--secondary: #f9b233;
	--secondary2: #e69608;
	--black: #222;
	--d-font: 'lato', sans-serif;
	--d-font2: 'lato', sans-serif;
	--white: #fff;
	--d-blue: #0e71b8;
	--d-blue2: #0e71b8;
	--h1-font: 'lato', sans-serif;
	--grey: #d3d3d3;
	--d-grey: #727272;
	--blue3: #0e71b8;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #0e71b8;
	--li-blue: #daf0fb;
	--grey2: #7e7d7d;
	--red: #f55454;
	--grey3: #f3f3f3;
	--d-font3: 'Ubuntu', sans-serif;
	--grey4: #efefef;
	--grey5: #ccc;


	/*Calendar colors*/
	--not_available: var(--secondary);
	/* --disabled: rgba(86, 90, 92, .5); */
	--disabled: transparent;
	--available: #fff;
	--selected: var(--secondary);
	--selection_range: var(--primary);
	--selected_border: #fff;
	--text_color: #000;
}