.main-footer {
	background-color: var(--white);
	position: relative;

	padding: 40px 0 0;

	.newsletter-post-bl {
		margin-bottom: 50px;
		z-index: 5;
		width: 100%;
		padding: 0 20px;

		.contact-form {
			margin-bottom: 0;

			p:last-child {
				margin-bottom: 0;
			}

			p,
			.heading1 {
				color: var(--secondary);
			}
		}
	}

	.footer-icon {
		position: absolute;
		left: 0px;
	}




	.footer-cont {
		border-bottom: 2px solid var(--secondary);
		border-top: 2px solid var(--secondary);
		max-width: 1360px;
		margin: 0 auto;
		padding: 40px 0;

		@media (max-width:992px) {
			padding-bottom: 0;
		}
	}

	.newslet-bl {
		background-color: #ededed;
		padding: 15px;
		border-radius: 5px;
		max-width: 1210px;
		margin: 0 auto;
		text-align: center;

		@media (max-width:992px) {
			padding: 16px 20px 10px;
		}

		h4 {
			color: var(--primary);
			font-size: 18px;
			margin-bottom: 25px;
			font-weight: 700;
		}

		h2 {
			color: var(--black);
			text-transform: uppercase;
			margin-bottom: 40px;
			font-size: 21px;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}

		.widt-cont-bl {
			background-color: #fff;
			color: var(--black);
			border-radius: 30px;
			padding-left: 15px;
			display: flex;
			max-width: 420px;
			margin: 0 auto;

			@media (max-width:992px) {
				border: none;
			}

			.form-control {
				border: none;
				background-color: transparent;
				font-size: 16px;
				font-family: var(--d-font);
				height: inherit;
				padding: 10px 20px;
				color: #777;

				@media (max-width:992px) {
					padding: 7px 0;
					font-size: 13px;
				}
			}

			.btn-primary {
				padding: 12px 40px;
				border-top-right-radius: 30px;
				border-bottom-right-radius: 30px;
				color: #fff;
				font-size: 18px;

				@media (max-width:992px) {
					padding: 7px 20px;
					font-size: 14px;
				}
			}
		}
	}

	.social-footer {
		max-width: 210px;
		margin: 0 auto 35px;

		@media (max-width:992px) {
			text-align: center;
		}

		a {
			width: 40px;
			height: 40px;
			display: inline-block;
			margin-right: 3px;
			margin-right: 15px;
			text-align: center;
			border-radius: 50%;
			font-size: 18px;
			text-decoration: none;
			color: var(--secondary);
			line-height: 40px;
			background-color: var(--white);

			&:hover {
				background-color: var(--primary);
				color: var(--black);
			}

			&:last-child {
				margin-right: 0;
			}

		}
	}

	.ft-logo {
		margin-bottom: 35px;
		max-width: 210px;

		@media (max-width:992px) {
			text-align: center;
			margin: 0 auto 35px;
		}
	}

	.widget-bl {
		@media (max-width:992px) {
			margin-bottom: 20px;
			border-bottom: 1px solid #078dcc;
			padding-bottom: 25px;
			margin-bottom: 25px;
		}

		&.no-bdr {
			border-bottom: none;
			margin-bottom: 0;
		}

		.widgt-title {
			font-family: var(--d-font);
			color: var(--black);
			margin-bottom: 40px;
			font-weight: bold;
			display: block;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}
	}

	.blue-li {
		li {
			list-style: none;
			margin-bottom: 22px;

			a {
				color: var(--black);
				font-size: 18px;
				font-family: var(--d-font2);

				&:hover {
					color: var(--primary);
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.icon-white-li {


		@media (min-width:993px) {
			display: flex;
			flex-wrap: wrap;
		}

		li {
			list-style: none;
			margin-bottom: 22px;
			position: relative;
			padding-left: 35px;
			color: var(--black);

			&:last-child {
				margin-bottom: 0;
			}

			i {
				font-size: 24px;
				opacity: .5;
				position: absolute;
				left: 0;
				top: 2px;
				color: var(--black);

				&.fa-phone-alt {
					top: 5px;
				}
			}

			a {
				color: var(--black);
				font-size: 18px;
				font-weight: 400;
				margin: 0;

				&:hover {
					color: var(--primary);
				}
			}

			h4 {
				margin: 0;
				font-weight: 400;
			}

			span {
				font-size: 13px;
				opacity: .5;
				display: block;

			}
		}
	}

	.copyright {
		padding: 12px 0;
		color: var(--black);
		font-size: 16px;
		background-color: var(--white);

		.logo-snippet-box {
			display: block;
			width: 100%;
			max-width: 259px;
			margin: 1px auto;
			padding: 1px 0;
			text-align: center;
			position: relative;
			font-size: 5px;

			.logo-snippet {
				display: inline-block;
				height: 5px;
				width: 32%;
				padding: 0;

				margin: 0 1px;
			}

			.logo-green {
				background-color: #575756;
			}

			.logo-yellow {
				background-color: #F9B233;
			}

			.logo-blue {
				background-color: #1D71B8;
			}
		}

		@media (max-width:992px) {
			text-align: center;
		}
	}
}