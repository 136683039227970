@import "setup.css";

.header-style2,
.header-style3 {
	.mainHeader {
		border-bottom: 3px solid #f9f9f9;
		background-color: rgba(255, 255, 255, 1) !important;
		padding: 0 !important;

		.top-right {
			display: none !important;
		}

		.navbar-toggler {
			color: var(--black) !important;
		}

		.navbar-area {
			.logo {
				a {
					max-width: 230px;

					@media (max-width: 1240px) {
						max-width: 160px;
					}
				}
			}


			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--black);
						}
					}
				}
			}


		}
	}

	.navbar-nav-right {
		.dropdown-toggle {
			color: var(--black) !important;

			&:hover {
				color: var(--black) !important;
			}
		}

		a {
			text-decoration: inherit;
			text-shadow: none !important;
			color: var(--black) !important;

			&:hover {
				color: var(--primary) !important;
			}
		}


	}
}



.vo-nav-bar.affix {
	.navbar-nav-right {
		a {
			text-shadow: none !important;
		}
	}
}

.vo-nav-bar {
	.navbar-nav-right {
		margin-right: 20px;
		display: flex;

		.nav-item {
			list-style: none;
			display: flex;

			&:hover {
				.dropdown-menu {
					display: block;
				}

				>a {
					background-color: var(--secondary);
				}
			}


			a {
				font-size: 18px;
				color: var(--white);
				display: inline-block;
				padding: 6px 10px;
				background: transparent;
				text-align: left;
				text-transform: uppercase;
				font-weight: 700;
				border-radius: 3px;
				margin: 0;
				text-shadow: 0 0 10px rgb(0 0 0 / .7);

				@media (max-width:1400px) and (min-width: 768px) {
					padding: 5px 8px;
					font-size: 14px;
				}

				@media (max-width:1240px) {
					padding: 7px 0;

				}
			}
		}

		.dropdown-menu {
			min-width: 260px;
			left: 0;
			padding: 0;
			background-color: var(--secondary);
			border: none;
			border-radius: 0 3px 3px 3px;
			margin: -1px 0 0;

			li {
				float: left;
				width: 100%;
				border-bottom: 1px solid hsla(0, 0%, 100%, .2);
				margin: 0;
				padding: 8px 20px;
				list-style: none;
			}

			a {
				color: var(--white);
				font-size: 14px;
				padding: 0;
				text-shadow: 0 0 10px rgb(0 0 0 / .3);


				&:hover {
					color: var(--primary);
				}
			}
		}
	}

}

.navbar-area {
	.logo {

		a {
			max-width: 270px;
			background: #fff;
			padding: 1px 15px;
			border-radius: 9px;
			display: inline-block;
		}
	}

	>.srch-btn {
		color: var(--primary) !important;





		i.fal,
		i.far {
			@media (max-width:1240px) {
				display: none;
			}
		}
	}
}

.search-bl-mob {
	@media (max-width:1300px) {
		margin-top: 10px;
	}

	.srch-btn {
		@media (max-width:1300px) {
			padding: 5px 12px !important;
		}
	}
}



.nav-right {
	display: flex;
	align-items: center;
	position: relative;

	.srch-btn {
		padding: 5px 8px;
	}

	a {
		color: var(--white);
		font-size: 16px;
		margin-left: 8px;
	}

	.fa {
		font-size: 24px;
		color: var(--secondary);
	}

	.fav-count {
		color: var(--secondary);
		position: relative;
		margin-left: 8px;


		.sub-count {
			display: block;
			position: absolute;
			top: -6px;
			left: 7px;
			width: 18px;
			height: 18px;
			background: red;
			background: var(--primary);
			color: var(--white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;
			border-radius: 50%;
		}
	}
}

.vo-nav-bar {

	&.mainHeader {
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 50;
		display: flex;
		align-items: center;
		padding: 28px 0;
		transition: all ease-in .2s;
		background-color: rgba(255, 255, 255, 0);

		@media (max-width:1240px) {
			padding: 10px 0 !important;
			border-bottom: 3px solid transparent;
			top: 0;
		}

		.navbar-area {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;

			@media (max-width:1240px) {
				padding: 0;
				margin-left: 60px;
			}

			.objsearch {
				border-bottom-left-radius: 20px;
				border-top-left-radius: 20px;
				border: none;
			}

			.srch-btn {
				font-size: 16px;
				color: var(--white);
				border-bottom-right-radius: 20px;
				border-top-right-radius: 20px;



				@media (max-width:1240px) {
					margin-r: 0;
					top: 5px;
					ight: 5px;
					color: var(--primary);
				}
			}
		}

		.top-right {
			position: absolute;
			right: 0;
			top: -35px;

			.header-icon {
				position: absolute;
				left: -25px;
				vertical-align: unset !important;
			}

			@media (max-width:1300px) {
				display: none !important;
			}

			.fa {
				font-size: 18px;

				&.fa-phone-alt {
					transform: rotate(45deg);
				}
			}
		}

		.logo {
			a {
				@media (max-width:1500px) {
					width: 200px;
				}

				@media (max-width:1240px) {
					max-width: 160px;
					padding: 2px 5px;
				}
			}


		}



		.topNav {
			.navbar {
				min-height: inherit;
				margin: 0;

				@media (min-width:1381px) {
					position: relative;
					left: 118px;
				}

				@media (min-width:1241px) {

					left: 108px;
				}

				@media (max-width:1240px) {
					position: inherit;
				}
			}

			.navbar-toggler {
				background: transparent;
				font-size: 24px;
				color: var(--white);
				padding: 0;
				border: none;
				box-shadow: none;
				display: none;

				@media (max-width:1240px) {
					display: inline-block;
					position: absolute;
					left: 20px;
					top: 16px;

				}
			}



			ul {
				margin: 0;
				padding: 0px;

				&.two-col-drop {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 440px;

					li {
						@media (min-width:993px) {
							width: 50% !important;
						}
					}
				}

				li {
					list-style: none;
					padding: 0px;

					@media (min-width:1501px) {
						margin: 0 5px;
					}


					a {
						font-size: 18px;
						color: var(--white);
						display: inline-block;
						padding: 6px 10px;
						background: transparent;
						text-align: left;
						text-transform: uppercase;
						font-weight: bold;
						text-shadow: 0 0 10px rgba(0, 0, 0, .7);
						border-radius: 3px;


						@media (max-width:1400px) and (min-width: 768px) {
							padding: 5px 8px;
							font-size: 14px;
						}

						@media (max-width:1240px) {
							padding: 7px 0;

						}

						span {
							padding: 3px 5px;
							border-radius: 3px;
							background-color: var(--secondary);
							color: var(--white);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}

					&:hover {
						a {
							background-color: var(--secondary);

						}

						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						min-width: 260px;
						left: 0px;
						margin: 0px;
						padding: 0;
						background-color: var(--secondary);
						border: none;
						border-radius: 0 3px 3px 3px;
						margin-top: -1px;

						@media (max-width:1240px) {
							ul {
								padding: 0;
							}
						}

						li {
							float: left;
							width: 100%;
							border-bottom: 1px solid rgba(255, 255, 255, .2);
							margin: 0px;
							padding: 8px 20px;

							&:last-child {
								border-bottom: none;
							}
						}

						a {
							font-size: 14px;
							display: block;
							padding: 0;
							text-shadow: 0 0 10px rgba(0, 0, 0, .3);

							&:hover {
								color: var(--primary);
							}
						}
					}
				}

			}
		}
	}

	&.affix {
		border-bottom: 3px solid #f9f9f9;
		top: 0;
		padding: 0 !important;

		background-color: rgba(255, 255, 255, 1);

		.nav-right {
			a {
				color: var(--black);
			}

			.top-right {
				display: none;
			}
		}

		.navbar-toggler {
			color: var(--black) !important;
		}

		.navbar-area {

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--black);
						}
					}
				}
			}


		}
	}

	@media (max-width:1240px) and (min-width: 768px) {
		.navbar-collapse.collapse {
			display: none !important
		}

		.navbar-collapse.collapse.in,
		.navbar-collapse.collapsing {
			display: block !important
		}
	}

	@media screen and (max-width: 1240px) {

		.topNav {

			.navbar-collapse {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 58px;
				background: var(--primary);
				padding: 0px;
				overflow: scroll !important;
				max-height: 450px;


				ul {
					magin: 0px;
					float: left;
					width: 100%;
					padding: 10px 15px;

					&.dropdown {
						i {
							margin-left: 5px;
						}

					}

					li {
						float: left;
						width: 100%;

						margin: 0px;

						a {
							color: #ffffff;
						}

						&:hover {
							a {
								color: #ffffff;
							}

							.dropdown-menu {
								display: none;
							}
						}

						&.open {
							.dropdown-menu {
								display: block;
								position: static;
							}
						}

						.dropdown-menu {
							position: static;
							float: none;
							width: auto;
							margin-top: 0;
							background-color: transparent;
							border: 0;
							box-shadow: none;

						}
					}
				}
			}
		}
	}
}

.mm-page {
	&.mm-slideout {
		z-index: inherit;
	}
}

@media (max-width: 1240px) {

	.mobile-navigation {
		ul.mm-listview {
			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 100px;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		z-index: 51;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.navbar-nav,
	.navbar-nav>li {
		float: none !Important;
		padding: 0 15px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-menu_offcanvas {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_offcanvas {
		display: block;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.mm-navbar__title>span {
		color: var(--white);
		font-size: 20px;
		font-weight: bold;
	}

	.dropdown {
		i {
			display: none;
		}
	}



	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin: 0;
	}

	.mm-listitem {
		text-align: center;
		font-size: 20px;
		color: var(--white);

		a {
			span {
				padding: 0 8px 2px;
				line-height: 1;
				font-size: 14px;
				border-radius: 3px;
				background-color: var(--primary);
				position: relative;
				top: -2px;
			}
		}


	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 12px;
		color: var(--white);
	}

	.mm-btn_next {
		position: absolute;
		left: 0;
		width: 100%;
		height: 30px;
		top: 6px;

		&:after {
			right: 30px;
		}
	}


	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--white);
	}

	.mm-listview {
		max-height: 75vh;
		padding-bottom: 35px;
	}


	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
		z-index: 51;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}
}